import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCRPE3s9YBNOcvTneAGILAMI4lpo4afClM",
  authDomain: "graoo-landing.firebaseapp.com",
  projectId: "graoo-landing",
  storageBucket: "graoo-landing.appspot.com",
  messagingSenderId: "570756973045",
  appId: "1:570756973045:web:88965b5f94d4a8d9e920bc",
  measurementId: "G-5M3NNGHRSZ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firebase = { app, analytics };
